export function filter(){
  (function() {
    // create empty object in the global em var, dont forget to add the init call in the main.js!
    em.filter = {};

    // call any functions to be trigger on dom ready
    em.filter.init = function() {
      em.filter.capture();
      em.filter.setup();
      em.filter.fixHeights();
    };

    em.filter.capture = function() {
      em.filter.elements = $('.js-filter');
    };

    em.filter.setup = function() {
      em.filter.setupFilters();
    };

    em.filter.fixHeights = function() {
      $(window).on('load resize', function() {
        var maxHeight = 0;
        $('.js-filter').each(function() {
          if ($(this).height() > maxHeight) {
            maxHeight = $(this).height();
          }
        });

        // Use element actual height on mobile with new project listing
        if ($('.js-filter:has(".js-filter__dynamic")')) {
          $('.js-filter').height('auto');
        } else {
          $('.js-filter').height(maxHeight);
        }

      });
    };

    em.filter.setupFilters = function() {
      // $('body').on('click', 'a.js-filter-category', function(e) {
      $('a.js-filter-category').on('click', function(e) {
        e.preventDefault();
        $('.js-filter-category').removeClass('is-active');
        $(this).addClass('is-active');
        var filter = $(this).data('filter');
        em.filter.filter(filter);
      });
    };

    em.filter.filter = function(filter) {
      const wideItem = $('.l-listing-grid__item--wide');
      if (filter == 'all') {
        //$('.js-filter').filter(":hidden").fadeIn(400);
        $('.js-filter').filter(":visible").fadeOut(200, function() {
          if (wideItem.length) {
            wideItem.removeClass('filtered');
          }
          $('.js-filter').fadeIn(200);
        });
      } else {
        $('.js-filter').filter(":visible").fadeOut(200, function() {
          if (wideItem.length) {
            wideItem.addClass('filtered');
          }
          $('.js-filter--' + filter).fadeIn(200);
        });
 
        /*$(".js-filter:not('.js-filter--" + filter + "')").fadeOut(300, function() {
          $('.js-filter--' + filter).fadeIn(300);
        });*/
      }
    };
  })();
  }
