export function images(){
(function() {
  // create empty object in the global em var, dont forget to add the init call in the main.js!
  em.images = {};

  // call any functions to be trigger on dom ready
  em.images.init = function() {
    em.images.setup();
  };

  em.images.setup = function() {
    $('img').each(function(i) {
      var $image = $(this);

      $image.imagesLoaded({}, function(instance) {
        $image.css({
          opacity: 1,
        });
      });
    });

    $('.js-load-background').each(function(i) {
      var $section = $(this);

      $section.imagesLoaded(
        {
          background: true,
        },
        function(instance) {
          $section.css({
            opacity: 1,
          });
        },
      );
    });

    $(window).on('scroll load resize', function() {
      var docHeight = $(document).height();
      var half = docHeight / 2;

      var heroScroll = $(window).scrollTop() > window.innerHeight;

      if (heroScroll) {
        $('.single .b-hero--fixed').hide();
      } else {
        $('.single .b-hero--fixed').show();
      }
    });

    $(document).ready(function() {
      var installFixedHero = setInterval(function() {
        if (
          $('.single div[class^="scheme--"]')
            .children('.b-hero')
            .index() == 0
        ) {
          if (
            $('.single div[class^="scheme--"]')
              .children('.b-hero:first')
              .css('opacity') == 1
          ) {
            $('.single div[class^="scheme--"]')
              .children('.b-hero:first')
              .each(function(i) {
                $(this).addClass('b-hero--fixed');
                var height = $(this).outerHeight();
                $('body').css('margin-top', height);
                clearInterval(installFixedHero);
              });
          }
        } else {
          clearInterval(installFixedHero);
        }
      }, 100); // check every 100ms
    });
  };
})();
}