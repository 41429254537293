export function projectList(){
(function() {
  // create empty object in the global em variable
  em.projectList = {};

  // call any functions to be trigger on dom ready
  em.projectList.init = function() {
    em.projectList.setup();
  };

  em.projectList.setup = function() {
    $('.js-project-list').imagesLoaded(function() {
      em.projectList.setupEqualRows();
    });
  };

  em.projectList.setupEqualRows = function() {
    $('.js-project-list').each(function() {
      var $block = $(this);

      em.projectList.calculateOffsets($block);
    });
  };

  em.projectList.calculateOffsets = function($block) {
    var numOfColumns = Modernizr.mq('only screen and (min-width: 992px)')
      ? 3
      : 2;
    var maxHeight = 0;
    var items = $block.find('.js-project-item');

    // Find the tallest image
    items.each(function(index) {
      var self = $(this);
      var image = self.find('img');
      var itemNumber = index + 1;
      var itemHeight;

      // reset margin
      image.css({
        marginTop: 0,
      });

      itemHeight = image.outerHeight() + 1; // Add one to avoid sub-pixel issues

      if (itemHeight > maxHeight) {
        maxHeight = itemHeight;
      }

      // check if we've reached the end of the row
      if (itemNumber % numOfColumns === 0) {
        em.projectList.setOffsets(items, index, numOfColumns, maxHeight);
        maxHeight = 0;

        // otherwise, check if we've reached the last item
      } else if (itemNumber == items.length) {
        var numOfLeftoverItems = itemNumber % numOfColumns;

        // don't bother if only one item left over
        if (numOfLeftoverItems !== 1) {
          em.projectList.setOffsets(
            items,
            index,
            numOfLeftoverItems,
            maxHeight,
          );
        }
      }
    });
  };

  em.projectList.setOffsets = function(
    items,
    currentItem,
    numOfItems,
    rowHeight,
  ) {
    var currentRowItems = [];

    for (var i = currentItem; i > currentItem - numOfItems; i--) {
      currentRowItems.push(items[i]);
    }

    $.each(currentRowItems, function() {
      var self = $(this);
      var image = self.find('img');
      var h = image.height();

      image.css({
        marginTop: rowHeight - h + 'px',
      });
    });
  };

  em.projectList.resetOffsets = function($block) {
    $block.find('img').css({
      marginTop: 0,
    });
  };

  em.projectList.resize = function() {
    em.projectList.setupEqualRows();
  };
})();
}
