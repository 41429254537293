export function feed(){
(function() {
  // create empty object in the global em var, dont forget to add the init call in the main.js!
  em.feed = {};

  // call any functions to be trigger on dom ready
  em.feed.init = function() {
    em.feed.capture();
    em.feed.setup();
  };

  em.feed.capture = function() {
    em.feed.elements = $('.js-feed');
  };

  em.feed.setup = function() {
    em.feed.setupLoadMore();
    em.feed.setupFilters();
  };

  em.feed.setupLoadMore = function() {
    $('body').on('click', '.js-feed-more', function(e) {
      e.preventDefault();

      var $self = $(this);
      var $feed = $self.parents('.js-feed');
      var pageUrl = $self.attr('href');

      em.feed.loadItems(pageUrl, $feed, $self);
    });
  };

  em.feed.loadItems = function(pageUrl, $feed, $btn) {
    em.feed.disableButton($btn);

    var $grid = $feed.find('.js-feed-grid');
    var $wrapper = $feed.find('.js-feed-wrapper');

    $.ajax({
      url: pageUrl,
      method: 'GET',
      dataType: 'html',
      success: function(response) {
        em.feed.removeButton($btn);

        // Find the new items and append them to the grid
        $(response)
          .find('.js-feed-grid')
          .each(function() {
            $grid.append($(this).html());
            em.images.setup();
          });

        // Find the new load more button and append that to the feed wrapper
        $(response)
          .find('.js-feed-more')
          .each(function() {
            $wrapper.append($(this).parent());
          });
      },
    });
  };

  em.feed.setupFilters = function() {
    $('body').on('click', '.js-feed-filters a', function(e) {
      e.preventDefault();

      var $self = $(this);
      var $feed = $self.parents('.js-feed');
      var pageUrl = $self.attr('href');

      em.feed.filterFeed(pageUrl, $feed, $self);
    });
  };

  em.feed.filterFeed = function(pageUrl, $feed, $btn) {
    var $grid = $feed.find('.js-feed-grid');
    var $wrapper = $feed.find('.js-feed-wrapper');

    em.feed.disableGrid($grid);

    $.ajax({
      url: pageUrl,
      method: 'GET',
      dataType: 'html',
      success: function(response) {
        // Replace feed html with the new page's html
        $(response)
          .find('.js-feed-wrapper')
          .each(function() {
            $wrapper.html($(this).html());
            em.images.setup();
          });
      },
    });
  };

  em.feed.disableGrid = function($grid) {
    $grid.addClass('is-loading');
  };

  em.feed.disableButton = function($btn) {
    $btn.addClass('is-loading');
  };

  em.feed.removeButton = function($btn) {
    $btn.parent().remove();
  };
})();
}