// Avoid `console` errors in browsers that lack a console.
export function plugins(){
(function() {
  var method;
  var noop = function() {};
  var methods = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'timeStamp',
    'trace',
    'warn',
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

/**
 * simple-parallax-js - simpleParallax is a simple and lightweight JS plugin that gives your website parallax animations on the images
 * @version v4.2.0
 * @date: 06-03-2019 11:49:11
 * @link https://simpleparallax.com/
 */
('use strict');
var _createClass = (function() {
  function i(e, t) {
    for (var n = 0; n < t.length; n++) {
      var i = t[n];
      (i.enumerable = i.enumerable || !1),
        (i.configurable = !0),
        'value' in i && (i.writable = !0),
        Object.defineProperty(e, i.key, i);
    }
  }
  return function(e, t, n) {
    return t && i(e.prototype, t), n && i(e, n), e;
  };
})();
var _typeof =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? function(e) {
        return typeof e;
      }
    : function(e) {
        return e &&
          typeof Symbol === 'function' &&
          e.constructor === Symbol &&
          e !== Symbol.prototype
          ? 'symbol'
          : typeof e;
      };
function _classCallCheck(e, t) {
  if (!(e instanceof t))
    throw new TypeError('Cannot call a class as a function');
}
!(function(e, t) {
  typeof define === 'function' && define.amd
    ? define([], function() {
        return t(e);
      })
    : (typeof exports === 'undefined' ? 'undefined' : _typeof(exports)) ===
      'object'
    ? (module.exports = t(e))
    : (e.simpleParallax = t(e));
})(
  typeof global !== 'undefined'
    ? global
    : typeof window !== 'undefined'
    ? window
    : void 0,
  function(o) {
    var i = (function() {
      for (
        var e,
          t = 'transform webkitTransform mozTransform oTransform msTransform'.split(
            ' ',
          ),
          n = 0;
        void 0 === e;

      )
        (e = document.createElement('div').style[t[n]] != null ? t[n] : void 0),
          n++;
      return e;
    })();
    !(function() {
      for (
        var a = 0, e = ['ms', 'moz', 'webkit', 'o'], t = 0;
        t < e.length && !o.requestAnimationFrame;
        ++t
      )
        (o.requestAnimationFrame = o[e[t] + 'RequestAnimationFrame']),
          (o.cancelAnimationFrame =
            o[e[t] + 'CancelAnimationFrame'] ||
            o[e[t] + 'CancelRequestAnimationFrame']);
      o.requestAnimationFrame ||
        (o.requestAnimationFrame = function(e, t) {
          var n = new Date().getTime();
          var i = Math.max(0, 16 - (n - a));
          var s = o.setTimeout(function() {
            e(n + i);
          }, i);
          return (a = n + i), s;
        }),
        o.cancelAnimationFrame ||
          (o.cancelAnimationFrame = function(e) {
            clearTimeout(e);
          });
    })(),
      Element.prototype.matches ||
        (Element.prototype.matches =
          Element.prototype.msMatchesSelector ||
          Element.prototype.webkitMatchesSelector),
      Element.prototype.closest ||
        (Element.prototype.closest = function(e) {
          var t = this;
          if (!document.documentElement.contains(t)) return null;
          do {
            if (t.matches(e)) return t;
            t = t.parentElement || t.parentNode;
          } while (t !== null && t.nodeType === 1);
          return null;
        });
    var s = [];
    var t = -1;
    var a = void 0;
    var l = void 0;
    var r = void 0;
    var h = void 0;
    var m = !1;
    var u = (function() {
      function n(e, t) {
        _classCallCheck(this, n),
          (this.element = e),
          (this.elementContainer = e),
          (this.defaults = {
            delay: 0.6,
            orientation: 'up',
            scale: 1.3,
            overflow: !1,
            transition: 'cubic-bezier(0,0,0,1)',
            breakpoint: !1,
          }),
          (this.settings = Object.assign(this.defaults, t)),
          (this.settings.breakpoint &&
            document.documentElement.clientWidth <= this.settings.breakpoint) ||
            ((this.init = this.init.bind(this)),
            (this.animationFrame = this.animationFrame.bind(this)),
            (this.handleResize = this.handleResize.bind(this)),
            this.isImageLoaded(this.element)
              ? this.init()
              : this.element.addEventListener('load', this.init),
            s.push(this),
            m ||
              ((m = !0),
              this.getViewportOffsetHeight(),
              this.animationFrame()));
      }
      return (
        _createClass(n, [
          {
            key: 'init',
            value: function() {
              !1 === this.settings.overflow && this.wrapElement(),
                this.setStyle(),
                this.getElementOffset(),
                this.getTranslateValue(),
                this.animate(),
                o.addEventListener('resize', this.handleResize);
            },
          },
          {
            key: 'isImageLoaded',
            value: function() {
              return (
                !!this.element.complete &&
                (void 0 === this.element.naturalWidth ||
                  this.element.naturalWidth !== 0)
              );
            },
          },
          {
            key: 'isVisible',
            value: function() {
              return this.elementBottomX > a && this.elementTopX < l;
            },
          },
          {
            key: 'wrapElement',
            value: function() {
              var e = document.createElement('div');
              e.classList.add('simpleParallax'),
                (e.style.overflow = 'hidden'),
                this.element.parentNode.insertBefore(e, this.element),
                e.appendChild(this.element),
                (this.elementContainer = e);
            },
          },
          {
            key: 'unWrapElement',
            value: function() {
              var e = this.elementContainer.parentNode;
              if (e) {
                for (; this.elementContainer.firstChild; )
                  e.insertBefore(
                    this.elementContainer.firstChild,
                    this.elementContainer,
                  );
                e.removeChild(this.elementContainer);
              }
            },
          },
          {
            key: 'setStyle',
            value: function() {
              !1 === this.settings.overflow &&
                (this.element.style[i] = 'scale(' + this.settings.scale + ')'),
                this.settings.delay > 0 &&
                  (this.element.style.transition =
                    'transform ' +
                    this.settings.delay +
                    's ' +
                    this.settings.transition),
                (this.element.style.willChange = 'transform');
            },
          },
          {
            key: 'unSetStyle',
            value: function() {
              (this.element.style.willChange = ''),
                (this.element.style[i] = ''),
                (this.element.style.transition = '');
            },
          },
          {
            key: 'getElementOffset',
            value: function() {
              var e = this.elementContainer.getBoundingClientRect();
              (this.elementHeight = e.height),
                (this.elementTopX = e.top + o.pageYOffset),
                (this.elementBottomX = this.elementHeight + this.elementTopX);
            },
          },
          {
            key: 'getViewportOffsetTop',
            value: function() {
              a = o.pageYOffset;
            },
          },
          {
            key: 'getViewportOffsetHeight',
            value: function() {
              r = document.documentElement.clientHeight;
            },
          },
          {
            key: 'getViewportOffsetBottom',
            value: function() {
              l = a + r;
            },
          },
          {
            key: 'handleResize',
            value: function() {
              this.getViewportOffsetHeight(),
                this.getElementOffset(),
                this.getRangeMax();
            },
          },
          {
            key: 'getRangeMax',
            value: function() {
              var e = this.element.clientHeight;
              (this.rangeMax = e * this.settings.scale - e),
                (this.settings.orientation !== 'down' &&
                  this.settings.orientation !== 'right') ||
                  (this.rangeMax *= -1);
            },
          },
          {
            key: 'getTranslateValue',
            value: function() {
              var e = (
                (l - this.elementTopX) /
                ((r + this.elementHeight) / 100)
              ).toFixed(1);
              return (
                (e = Math.min(100, Math.max(0, e))),
                this.oldPercentage !== e &&
                  (this.rangeMax || this.getRangeMax(),
                  (this.translateValue = (
                    (e / 100) * this.rangeMax -
                    this.rangeMax / 2
                  ).toFixed(0)),
                  this.oldTranslateValue !== this.translateValue &&
                    ((this.oldPercentage = e),
                    (this.oldTranslateValue = this.translateValue),
                    !0))
              );
            },
          },
          {
            key: 'animate',
            value: function() {
              var e = 0;
              var t = 0;
              var n = void 0;
              this.settings.orientation === 'left' ||
              this.settings.orientation === 'right'
                ? (t = this.translateValue + 'px')
                : (e = this.translateValue + 'px'),
                (n =
                  !1 === this.settings.overflow
                    ? 'translate3d(' +
                      t +
                      ', ' +
                      e +
                      ', 0) scale(' +
                      this.settings.scale +
                      ')'
                    : 'translate3d(' + t + ', ' + e + ', 0)'),
                (this.element.style[i] = n);
            },
          },
          {
            key: 'proceedElement',
            value: function(e) {
              e.isVisible() && e.getTranslateValue() && e.animate();
            },
          },
          {
            key: 'animationFrame',
            value: function() {
              if ((this.getViewportOffsetTop(), t !== a)) {
                this.getViewportOffsetBottom();
                for (var e = 0; e < s.length; e++) this.proceedElement(s[e]);
                (h = o.requestAnimationFrame(this.animationFrame)), (t = a);
              } else h = o.requestAnimationFrame(this.animationFrame);
            },
          },
          {
            key: 'destroy',
            value: function() {
              this.isDestroyed ||
                (this.unSetStyle(),
                !1 === this.settings.overflow && this.unWrapElement(),
                s.splice(s.indexOf(this), 1),
                s.length || ((m = !1), o.cancelAnimationFrame(h)),
                o.removeEventListener('resize', this.handleResize));
            },
          },
          {
            key: 'isDestroyed',
            get: function() {
              return s.indexOf(this) === -1;
            },
          },
        ]),
        n
      );
    })();
    return function(e, t) {
      var n = [];
      if (e.length) for (var i = 0; i < e.length; i++) n.push(new u(e[i], t));
      else n.push(new u(e, t));
      return n;
    };
  },
);
};