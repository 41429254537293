export function caseList() {
    
    var caseList = {};
    
    caseList.capture = function(){
        caseList.cases = jQuery('.b-case-list .b-case-list__grid-items');        
    };
    caseList.defineDevice = function(){
        caseList.isMobile = window.screen.width < 768;
        caseList.isIpad = window.screen.width >= 768 && window.screen.width <= 1024;
        caseList.isDesktop = window.screen.width > 1024;
    };
    caseList.attachEventHandlers = function() {
        // Mobile Handlers
        if(caseList.isMobile) caseList.cases.click(caseList.mobileClickCase);
        // Ipad EventHandlers
        if(caseList.isIpad) {
            caseList.cases.click(caseList.IpadTouchCase); 
            $(window).scroll(caseList.IpadScrollsAway);
        };

        // Desktop is different, so other tope of EventHandler
        if(caseList.isDesktop) {
            caseList.cases.mousemove(caseList.mouseHoversCase); 
            caseList.cases.mouseenter(caseList.MouseEntersCase);
            caseList.cases.mouseleave(caseList.MouseExitsCase);
        }
    };

    // Event handlers
    caseList.mobileClickCase = function (event) {
        // Prevent Default ONLY if it hasn't been clicked before, so it just opens
        if ($(this).hasClass('selected') === false){  
            event.preventDefault(); 
            // Classes Handling
            caseList.cases.removeClass('grey').removeClass('selected');
            caseList.cases.not(this).addClass('grey'); 
            $(this).addClass('selected'); 
            // Toggling handling
            $('.b-case-list__project-image').hide();
            var ImageWrapper = $('.b-case-list__project-image',this);
            ImageWrapper.toggle();
        }
    }
    caseList.IpadScrollsAway = function (event) {
        // Classes Handling
        caseList.cases.removeClass('grey').removeClass('active');
    };
    caseList.IpadTouchCase = function (event) {
        // Prevent Default ONLY if it hasn't been clicked before, so it just opens
        if ($(this).hasClass('active') === false){  
            event.preventDefault(); 
            // Classes Handling
            caseList.cases.removeClass('grey').removeClass('active');
            caseList.cases.not(this).addClass('grey');   
            $(this).addClass('active');                

            var ImageWrapper = $('.b-case-list__project-image',this);
            ImageWrapper.css({"left": event.clientX, "top": event.clientY});
        }
    };
    caseList.MouseEntersCase = function () {
        caseList.cases.not(this).addClass('grey');                       
    };
    caseList.MouseExitsCase = function () {
        caseList.cases.removeClass('grey');        
    };
    caseList.mouseHoversCase = function (event) {
        var ImageWrapper = $('.b-case-list__project-image',this);
        ImageWrapper.css({"left": event.clientX, "top": event.clientY});
    };
    
    caseList.defineDevice();
    caseList.capture();
    caseList.attachEventHandlers();

}