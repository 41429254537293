export function columns(){
(function() {
  em.columns = {};

  em.columns.init = function() {
    em.columns.setup();
  };

  em.columns.setup = function() {
    $('.js-columns-equal-images').imagesLoaded(function() {
      em.columns.setupEqualImages();
    });

    var fiftySixtyBlocks = $('.b-columns.fifty-sixty, .b-columns.sixty-fifty');
    if (fiftySixtyBlocks.length) {
      fiftySixtyBlocks.each(function(i, el) {
        var thing = el
          .querySelectorAll('.l-columns__item')
          [el.classList.contains('sixty-fifty') ? 0 : 1].querySelector('img');
        // eslint-disable-next-line no-new
        new simpleParallax(thing, {
          overflow: true,
          scale: 1.2,
        });
      });
    }
  };

  em.columns.setupEqualImages = function() {
    $('.js-columns-equal-images').each(function() {
      var $block = $(this);

      if (Modernizr.mq('only screen and (min-width: 768px)')) {
        em.columns.setOffsets($block);
      } else {
        em.columns.resetOffsets($block);
      }
    });
  };

  em.columns.setOffsets = function($block) {
    var maxHeight = 0;
    var $images = $block.find('img');

    // Find the tallest image
    $images.each(function() {
      var h = $(this).height();

      if (h > maxHeight) {
        maxHeight = h;
      }
    });

    // Compensate for the difference
    $images.each(function() {
      var h = $(this).height();

      $(this).css({
        marginTop: maxHeight - h + 'px',
      });
    });
  };

  em.columns.resetOffsets = function($block) {
    $block.find('img').css({
      marginTop: 0,
    });
  };

  em.columns.resize = function() {
    em.columns.setupEqualImages();
  };
})();
}