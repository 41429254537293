export function article(){
(function() {
  // create empty object in the global em var, dont forget to add the init call in the main.js!
  em.article = {};

  // call any functions to be trigger on dom ready
  em.article.init = function() {
    em.article.setImageHeights();
    em.article.setupParallax();
  };

  em.article.setImageHeights = function() {
    var $post = $('.js-single-post');
    var $images = $post.find('.js-article-images');

    $images.imagesLoaded(function() {
      $images.find('img').each(function(i) {
        var $image = $(this);

        $image.css({
          maxHeight: 'none',
        });

        if ($image.height() > $images.width()) {
          $image.css({
            maxHeight: $images.width(),
          });
        }
      });
    });
  };

  em.article.setupParallax = function() {
    $(window).scroll(function() {
      var $post = $('.js-single-post');
      var $images = $post.find('.js-article-images');
      var $content = $post.find('.js-article-content');
      var scrollMultiplier;
      var scrollAmount;

      if ($(window).width() >= 768) {
        scrollMultiplier =
          ($content.height() - $images.height()) / $post.height(); // This is just voodoo, don't try to comprehend.
        scrollAmount = $(window).scrollTop() * scrollMultiplier;
      } else {
        scrollAmount = 0;
      }

      $images.css({
        '-webkit-transform': 'translate3d(0px, ' + scrollAmount + 'px, 0px)',
        '-moz-transform': 'translate3d(0px, ' + scrollAmount + 'px, 0px)',
        '-ms-transform': 'translate3d(0px, ' + scrollAmount + 'px, 0px)',
        '-o-transform': 'translate3d(0px, ' + scrollAmount + 'px, 0px)',
        transform: 'translate3d(0px, ' + scrollAmount + 'px, 0px)',
      });
    });
  };

  em.article.resize = function() {
    em.article.setImageHeights();
  };
  
})();
}