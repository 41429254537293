export function captions(){
(function() {
  em.captions = {};

  em.captions.init = function() {
    em.captions.setup();
  };

  em.captions.setup = function() {
    // Our feed captions
    $('body').on('mouseenter', '.js-caption-image', function() {
      $(this)
        .find('.js-caption')
        .addClass('is-active');
    });

    $('body').on('mouseleave', '.js-caption-image', function() {
      $(this)
        .find('.js-caption')
        .removeClass('is-active');
    });

    // WP captions
    $('body').on('mouseenter', '.wp-caption', function() {
      $(this)
        .find('.wp-caption-text')
        .addClass('is-active');
    });

    $('body').on('mouseleave', '.wp-caption', function() {
      $(this)
        .find('.wp-caption-text')
        .removeClass('is-active');
    });
  };
})();
}