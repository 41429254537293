export function pageNavigation(){
  (function() {
  em.pageNavigation = {};

  em.pageNavigation.init = function() {
    $('.js-b-page-navigation__mobile-toggle').on('click', function() {
      $('body').toggleClass('b-page-navigation--open');
      return false;
    });

    var windowLastScrollPosition = 0;

    if ($(window).width() < 992) {
      $(window).scroll(function() {
        if (windowLastScrollPosition > $(window).scrollTop()) {
          $('.b-page-navigation').removeClass('page-navigation--scroll-hidden');
        } else if ($(window).scrollTop() > $('.b-page-navigation').height()) {
          $('.b-page-navigation').addClass('page-navigation--scroll-hidden');
        } else {
          $('.b-page-navigation').removeClass('page-navigation--scroll-hidden');
        }

        windowLastScrollPosition = $(window).scrollTop();
      });
    }

    if ($(window).width() >= 992) {
      $('.b-page-content').attr(
        'style',
        'margin-bottom:' + $('.b-footer').outerHeight() + 'px;',
      );
    }

    // $('.js-nav-logo').on({
    // 	mouseenter: function () {
    // 		$(this).siblings('.js-nav-stairs').addClass('hover');
    // 	},
    // 	mouseleave: function () {
    // 		$(this).siblings('.js-nav-stairs').removeClass('hover');
    // 	}
    // });

    // Add class to wrapper if scrolled over the height of the viewport
    $(window).scroll(function() {
      var isScrolled = $(window).scrollTop() > $(window).height();

      if (isScrolled) {
        $('body').addClass('is-scrolled');
      } else {
        $('body').removeClass('is-scrolled');
      }
    });

    $('.js-nav-back-top a').on('click', function(e) {
      e.preventDefault();

      zenscroll.toY(0);
    });
  };
})();
}