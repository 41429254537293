export function image_factbox(){
(function() {
  // create empty object in the global em var, dont forget to add the init call in the main.js!
  em.image_factbox = {};

  // call any functions to be trigger on dom ready
  em.image_factbox.init = function() {
    em.image_factbox.setup();
  };

  em.image_factbox.getTime = function getTime(timeZone) {
    var utcNow = new Date().toUTCString();
    var time = new Date(utcNow).toLocaleTimeString(
      'it-IT', // will output as 11:21:35
      { timeZone: timeZone },
    );

    return time;
  };

  em.image_factbox.setup = function() {
    $('.b-image_factbox__clock').each(function(i) {
      var el = $(this);
      var tz = el.attr('data-tz');
      var clockEl = el.find('.b-image_factbox__clock--time');

      clockEl.text(em.image_factbox.getTime(tz));

      setInterval(function() {
        clockEl.text(em.image_factbox.getTime(tz));
      }, 500);
    });
  };
})();
}