export function imagehero(){
(function() {
  // create empty object in the global em var, dont forget to add the init call in the main.js!
  em.imagehero = {};

  // call any functions to be trigger on dom ready
  em.imagehero.init = function() {
    em.imagehero.setup();
  };

  em.imagehero.setup = function() {
    /* $(".b-image-hero").each(function() {
            var elem = $(this);
            var pos = elem.offset().top; 
            var height = elem.outerHeight(); 
            console.log(pos);
            console.log(height);
            $(window).on('resize scroll', function() {
                var windowpos = $(document).scrollTop();
                if (windowpos >= pos) {
                    elem.addClass("b-image-hero__stick");
                    $('body').css('margin-top', height);
                } else {
                    elem.removeClass("b-image-hero__stick");
                }
            });
        }); */

    // I know that the code could be better.
    // If you have some tips or improvement, please let me know.

    $('.img-parallax').each(function() {
      var img = $(this);
      var imgParent = $(this).parent();
      function parallaxImg() {
        var speed = img.data('speed');
        var imgY = imgParent.offset().top;
        var winY = $(this).scrollTop();
        var winH = $(this).height();
        var parentH = imgParent.innerHeight();

        // The next pixel to show on screen
        var winBottom = winY + winH;

        // If block is shown on screen
        if (winBottom > imgY && winY < imgY + parentH) {
          // Number of pixels shown after block appear
          var imgBottom = (winBottom - imgY) * speed;
          // Max number of pixels until block disappear
          var imgTop = winH + parentH;
          // Porcentage between start showing until disappearing
          var imgPercent = (imgBottom / imgTop) * 100 + (50 - speed * 50);
        }
        img.css({
          top: imgPercent + '%',
          transform: 'translate(-50%, -' + imgPercent + '%)',
        });
      }
      $(document).on({
        scroll: function() {
          parallaxImg();
        },
        ready: function() {
          parallaxImg();
        },
      });
    });

    // Mobile actions
    /* if (window.innerWidth < 768) {
        $('.b-subnavi .b-subnavi__mobilemenu').on('click', function () {
            $('.b-subnavi__links').toggle();
            $('.b-subnavi__links__container').toggleClass('is-open');
        });
        } */
  };
})();
}