import { plugins } from './plugins';
// Removed addSearch from use since 27.5.2022
//import { addSearch } from './components/addsearch';
import { animations } from './components/animations';
import { article } from './components/article';
import { captions } from './components/captions';
import { caseList } from './components/caseList';
import { clipboard } from './components/clipboard';
import { columns } from './components/columns';
import { feed } from './components/feed';
import { filter } from './components/filter';
import { flickity } from './components/flickity';
import { footer } from './components/footer';
import { forms } from './components/forms';
import { helper } from './components/helper';
import { image_factbox } from './components/image_factbox';
import { imagehero } from './components/imagehero';
import { images } from './components/images';
import { modal } from './components/modal';
import { pageNavigation } from './components/pageNavigation';
import { projectList } from './components/projectList';
import { resize } from './components/resize';
import { youtubeAPIPlayer } from './components/youtubeAPIPlayer';
import { zoo_move } from './components/zoomove';

import '../css/scss/main.scss';

$(document).ready(function() {

  window.em = {};

  plugins();
  //addSearch();
  animations();
  article();
  captions();
  caseList();
  clipboard();
  columns();
  feed();
  filter();
  flickity();
  footer();
  forms();
  helper();
  image_factbox();
  imagehero();
  images();
  modal();
  pageNavigation();
  projectList();
  resize();
  youtubeAPIPlayer();
  zoo_move();

  /* $('.b-automatic-award-list__grid-container').masonry({

    itemSelector: '.b-automatic-award-list__grid-items',
    columnWidth: 330
  }); */

 /*  $('.zoo-item').ZooMove({

    image: $(this),
    cursor: 'true',
    scale: '1.15',
    move: 'true',
    over: 'false',
    autosize: 'false'

  }); */
  // Let's loop the em object for keys are call their init functions.
  for (var key in em) {
    // Check if init function exists.
    if (typeof em[key].init === 'function') {
      // Run init function.
      em[key].init();
    } else {
      console.error('Your block ' + key + " doesn't define init-method.");
    }
  }

  // General stuff
  window.viewportUnitsBuggyfill.init();

  /* Adjustments for Safari on Mac */
  if (
    navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.userAgent.indexOf('Mac') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1
  ) {
    // console.log('Safari on Mac detected, applying class...');
    $('html').addClass('safari-mac'); // provide a class for the safari-mac specific css to filter with
  }

  // Read hashtag and scroll to smoothly

  // to top right away
  if (window.location.hash) scroll(0, 0);
  // void some browsers issue
  setTimeout(function() {
    scroll(0, 0);
  }, 1);

  if (window.location.hash) {
    // smooth scroll to the anchor id
    var scrollTarget = document.getElementById(
      'scroll-' + window.location.hash.substr(1),
    );

    if (scrollTarget) {
      zenscroll.to(scrollTarget);
    }
  }

  // Do the same for links
  $('.js-anchor').on('click', function(e) {
    e.preventDefault();

    var url = $(this).attr('href');
    var hash = url.substring(url.indexOf('#'));
    var scrollTarget = document.getElementById('scroll-' + hash.substr(1));

    if (scrollTarget) {
      zenscroll.to(scrollTarget);
    }
  });
});
