export function flickity(){
(function() {
  // create empty object in the global em var, dont forget to add the init call in the main.js!
  em.flickity = {};

  // call any functions to be trigger on dom ready
  em.flickity.init = function() {
    em.flickity.setupSliders();
    em.flickity.setupEvents();
  };

  em.flickity.setupSliders = function() {
    var sharedOptions = {
      cellAlign: 'left',
      dragThreshold: 20,
      prevNextButtons: false,
      imagesLoaded: true,
      contain: false,
      pageDots: false,
      wrapAround: true,
    };

    $('.hs--style__normal .js-flickity-slider').flickity(
      Object.assign({}, sharedOptions, {
        autoPlay: 3000,
        pauseAutoPlayOnHover: false,
        lazyLoad: 2,
      }),
    );

    var carousel = $('.hs--style__1big2small .js-flickity-slider').flickity(
      Object.assign({}, sharedOptions, {
        lazyLoad: 4,
      }),
    );

    /**
     * Unfuck the layout in mobile after moving.
     * Caveat: only works properly if user is calm and doesn't try to spam through the carousel.
     */
    carousel.on('settle.flickity', function() {
      var data = carousel.data('flickity');

      if (data.selectedIndex !== 0) {
        carousel.find('.mobipadding').removeClass('mobipadding');
      }
    });
  };

  em.flickity.setupEvents = function() {
    var nextPrevHack = function(action) {
      return {
        // Go to next slide on click
        click: function(e) {
          e.preventDefault();

          var $slider = $(this)
            .closest('.b-horizontal-slideshow')
            .find('.js-flickity-slider');
          $slider.flickity(action, true);

          // Stop the autoplaying while hovering
        },
        mouseenter: function(e) {
          var $slider = $(this)
            .parent()
            .find('.js-flickity-slider');
          $slider.flickity('pausePlayer');
        },
        mouseleave: function(e) {
          var $slider = $(this)
            .parent()
            .find('.js-flickity-slider');
          $slider.flickity('unpausePlayer');
        },
      };
    };

    $('.js-flickity-next').on(nextPrevHack('next'));
    $('.js-flickity-previous').on(nextPrevHack('previous'));

    $('.js-flickity-more').on({
      // Stop the autoplaying while hovering
      mouseenter: function(e) {
        var $slider = $(this).parents('.js-flickity-slider');
        $slider.flickity('pausePlayer');
      },
      mouseleave: function(e) {
        var $slider = $(this).parents('.js-flickity-slider');
        $slider.flickity('unpausePlayer');
      },
    });
  };

  em.flickity.resizeSliders = function() {
    $('.js-flickity-slider').flickity('reposition');
  };
})();
}