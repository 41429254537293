export function footer(){
(function() {
  em.footer = {};

  em.footer.init = function() {
    // var footerHeight = $(window).width() >= 992 ? $('.b-footer').outerHeight() : 0;
    var footerHeight = $('.b-footer').outerHeight();

    $('.b-page-content').attr('style', 'margin-bottom:' + footerHeight + 'px;');
  };

  em.footer.resize = function() {
    em.footer.init();
  };
})();
}